/* This stylesheet generated by Transfonter (http://transfonter.org) on May 21, 2016 11:17 AM */
@font-face {
  font-family: 'KG Blank Space Solid';
  src: url("../../fonts/kg-blank-space/KGBlankSpaceSolid.eot");
  src: url("../../fonts/kg-blank-space/KGBlankSpaceSolid.eot?#iefix") format("embedded-opentype"), url("../../fonts/kg-blank-space/KGBlankSpaceSolid.woff2") format("woff2"), url("../../fonts/kg-blank-space/KGBlankSpaceSolid.woff") format("woff"), url("../../fonts/kg-blank-space/KGBlankSpaceSolid.ttf") format("truetype"), url("../../fonts/kg-blank-space/KGBlankSpaceSolid.svg#KGBlankSpaceSolid") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'KG Blank Space Sketch';
  src: url("../../fonts/kg-blank-space/KGBlankSpaceSketch.eot");
  src: url("../../fonts/kg-blank-space/KGBlankSpaceSketch.eot?#iefix") format("embedded-opentype"), url("../../fonts/kg-blank-space/KGBlankSpaceSketch.woff2") format("woff2"), url("../../fonts/kg-blank-space/KGBlankSpaceSketch.woff") format("woff"), url("../../fonts/kg-blank-space/KGBlankSpaceSketch.ttf") format("truetype"), url("../../fonts/kg-blank-space/KGBlankSpaceSketch.svg#KGBlankSpaceSketch") format("svg");
  font-weight: normal;
  font-style: normal;
}

.font-blank-space {
  font-family: 'KG Blank Space Sketch';
}

@media (max-width: 543px) {
  .slidea .slidea-title {
    font-size: 3rem;
  }
}
